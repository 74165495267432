@import "../1.settings/settings.colors";

$sidebar--width: 250px;

.page--wrapper {
  background-color: #c8c8c8;
  height: 100vh;
  // min-height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
  .column {
    &--left {
      width: $sidebar--width;
      background-color: #fff;
      // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      background-color: $color--pink;
    }
    &--right {
      width: calc(100% - $sidebar--width);
      // background-color: #f00;
    }
  }
  .content {
    padding: 20px;
  }
}
