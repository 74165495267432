@import './settings.colors';

$navbar--height: 65px;

body {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  background-color: #f7f7f7 !important;
  overflow-x: hidden;
  overflow-y: hidden;
  color: #000 !important;
}

.mat-drawer-container,
.mat-drawer-content {
  height: auto !important;
  overflow: hidden !important;
}

input, textarea {
    font-size: 14px !important;
    &::placeholder {
        color: #b8b8b8 !important;
    }
}

textarea {
    resize: none !important;
}

