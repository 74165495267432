.box-shadow {
    box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
}

.box-shadow--light {
    // box-shadow: rgb(33 35 38 / 10%) 0px 10px 10px -10px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.box-shadow--hover {
    box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
}

.w10 {
    width: 10% !important;
}

.w15 {
    width: 15% !important;
}

.w20 {
    width: 20% !important;
}

.w30 {
    width: 30% !important;
}

.w35 {
    width: 35% !important;
}

.w40 {
    width: 40% !important;
}

.w50 {
    width: 50% !important;
}

.w60 {
    width: 60% !important;
}

.w70 {
    width: 70% !important;
}

.w80 {
    width: 80% !important;
}

.w90 {
    width: 90% !important;
}

.w100 {
    width: 100% !important;
}

.no-padding {
    &--right {
        padding-right: 0 !important;
    }

    &--left {
        padding-left: 0 !important;
    }

    &--top {
        padding-top: 0 !important;
    }

    &--bottom {
        padding-bottom: 0 !important;
    }
}

.no-margin {
    &--right {
        margin-right: 0 !important;
    }

    &--left {
        margin-left: 0 !important;
    }

    &--top {
        margin-top: 0 !important;
    }

    &--bottom {
        margin-bottom: 0 !important;
    }
}

.square-borders {
    &--right {
        input {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }

    }

    &--left {
        input {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }

    }
}

.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.left {
    justify-content: left !important;
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: $color--bordergrey transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: $color--bordergrey;
    border-radius: 20px;
    border: 3px solid transparent;
}