.truncate-text {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.wrap-text {
    white-space: inherit !important;

}

.rotate--45 {
    transform: rotate(45deg);
}

.rotate--90 {
    transform: rotate(90deg);
}

.rotate--180 {
    transform: rotate(180deg);
}
