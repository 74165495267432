/*------ 1.Settings - Global and component settings ------*/

@import '1.settings/settings.global';
@import '1.settings/settings.colors';
@import '1.settings/settings.fonts';
@import '1.settings/settings.material';

/*------ 2.Tools - Tools and mixins ------*/

@import '2.tools/tools.mixins';
@import '2.tools/tools.media';
@import '2.tools/tools.utilities';

/*------ 3.Generic - Resets and normalising ------*/

@import '3.generic/generic.normalize';
@import '3.generic/generic.box-sizing';
@import '3.generic/generic.reset';

/*------ 4.Elements - Generic html elements ------*/

@import '4.elements/elements.page';
@import '4.elements/elements.headings';
@import '4.elements/elements.links';
@import '4.elements/elements.lists';
@import '4.elements/elements.images';
@import '4.elements/elements.buttons';
@import '4.elements/elements.toast';
@import '4.elements/elements.dialogs';
@import '4.elements/elements.ngselect';
@import '4.elements/elements.datepicker';
@import '4.elements/elements.spinners';
@import '4.elements/elements.slider';
@import '4.elements/elements.inputs';
@import '4.elements/elements.tooltip';

/*------ 5.Object - Object html elements ------*/

@import '5.objects/objects.layout';
@import '5.objects/objects.wrappers';
@import '5.objects/objects.grid';
@import '5.objects/objects.widgets';

/*------ 6.Page - Page html elements ------*/

@import '6.page/page.landing-page';
@import '6.page/page.login';

/*------ 7.Elements - Components ------*/
@import '7.components/component.forms';

/*------ 8.Elements - Components ------*/

@import '8.trumps/trumps.helpers';
@import '8.trumps/trumps.align';
