
.text--center {
    text-align: center;
}

.text--right {
    text-align: right;
}

.text--left {
    text-align: left;
}

.float--right {
    float: right;
}

.float--left {
    float: left;
}

