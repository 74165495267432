.widget--grid {
  margin-bottom: 20px;
  &.games {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  &.charts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  .widget--item {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: #fff;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    border-radius: 5px;
    .widget--header {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-bottom: 2px solid $color--pink;
    }
    .widget--body {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      padding: 10px;
      .details--list {
        display: flex;
        flex-direction: column;
        .item {
          margin-bottom: 10px;
        }
      }
    }
    .widget--footer {
    }

    .title {
      font-size: 1.3em;
      font-weight: bold;
      &.inner {
        font-size: 1.1em;
      }
    }
    .date {
      color: $color--pink;
      font-weight: bold;
    }
    .amount {
      color: $color--pink;
      font-weight: bold;
    }

    &.span--2 {
      grid-column: 1/3;
    }

    &.span--5 {
      grid-column: 1/5;
    }
  }
}