$color--lightgrey: #efefef;
$color--bordergrey: #b8b8b8;
$color--grey2: #f0f0ef;

$color--muted: #A9A9A9;

$color--pink: #ee2b7f;
$color--lightpink: #fabfd9;
$color--gold: #cfa02a;
$color--lightblue: #35b5e9;
$color--green: #97aa00;
$color--navy: #262a60;
$color--red: #e30613;
$color--blue: #1d71b8;
$color--grey: #d3d3d3;

.bg--pink {
    background-color: $color--pink;
    color: #fff;
}

$color--error: #d9534f;
$color--warning: #f0ad4e;
$color--notification: #5bc0de;
$color--success: #5cb85c;
